import React, {useEffect, useRef} from "react";
import PropTypes from 'prop-types';

AskTheCommunityStep.propTypes = {
  index: PropTypes.number
}

export function AskTheCommunityStep(props) {
  const stepRef = useRef(null);
  const { nested = false } = props;

  // On mount, focus the step and scroll into view.
  useEffect(() => {
    stepRef.current.focus({ preventScroll: true });
    window.scrollTo({
      top: (stepRef.current.offsetTop - 120),
      behavior: "smooth"
    });
  }, [])

  return (
    <div ref={stepRef} tabIndex={0} className={"support-form-step support-form-ask-the-community-step " + (nested && 'support-form-step--nested')}>
      <div className="support-form-step-header">
        {!nested &&
          <div className="support-form-ask-the-community-step__icon-circle">
            <div className="support-form-ask-the-community-step__icon">
              <span className="support-form-ask-the-community-step__glyph glyph-core-person-group-fill"></span>
            </div>
          </div>
        }
        <div className="support-form-step-header__side-line" />
        {!nested && <h2 className="support-form-step-header__question">Ask the Homey Community</h2>}
        <p className="support-form-step-header__description">Our awesome community might be able to answer your question.</p>
      </div>

      <a className="support-form-ask-the-community-step__button" href="https://community.homey.app/">
        <span>Homey Community Forum</span>
        <span className="mask-arrow-right"/>
      </a>

      <p className="support-form-ask-the-community-step__text">
        As much as we’d love to help, we focus on general support and can’t assist with custom use-cases.
        For tailored advice, visit the Homey Community forum, where experienced users might be able to help.
      </p>
    </div>
  );
}
