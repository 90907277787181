import {useState} from "react";
import fetch from "node-fetch";

import {dataManager} from "../managers/DataManager";
import {userManager} from "../managers/UserManager";
import {dataKeys} from "../enums/dataKeys";

/*
 * This hook returns an object with sendTicket function, a Promise to send a ticket to Zendesk.
 * In addition, this hook returns the boolean ticketIsSending state, ticketError string and setTicketError function.
 * example:
 * const [ticketApi, ticketIsSending, ticketError, setTicketError] = useTicketApi();
 * const ticketResponse = await ticketApi.sendTicket();
 */
export function useTicketApi() {
  const user = userManager.getUser();
  const [ticketIsSending, setTicketIsSending] = useState(false);
  const [ticketError, setTicketError] = useState("");

  // Upload any attachments and send ticket to Zendesk.
  async function sendTicket() {
    try {
      setTicketError("");
      setTicketIsSending(true);
      const ticketData = _getTicketData();
      const attachments = _getAttachments();

      // Wait for pending Promises. Errors are handled in respective calls (e.g. getDiagnosticReport()).
      await Promise.allSettled(Object.values(dataManager.pendingPromises));

      // Upload attachments to Zendesk and return upload tokens
      ticketData.uploadTokens = await Promise.all(
        Object.values(attachments).map(async attachment => {
          const filename = attachment.name;
          const mimetype = attachment.type;

          const uploadResponse = await fetch(`/api/zendesk/attachment?filename=${filename}&mimetype=${mimetype}`, {
            method: 'POST',
            body: attachment,
          });

          // Handle invalid upload response
          if (!uploadResponse.ok) {
            setTicketError(`We’ve encountered a problem uploading ${filename}. Please try again!`);
            throw new Error(`Could not upload file! ${uploadResponse.statusText}`);
          }

          const uploadResponseBody = await uploadResponse.json();

          return uploadResponseBody.token;
        })
      );

      // Send ticket to Zendesk
      const ticketResponse = await fetch('/api/zendesk/ticket', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(ticketData),
      });

      // Handle invalid ticket response
      if (!ticketResponse.ok) {
        setTicketError("We’ve encountered a problem sending your message. Please try again.");
        throw new Error(`Could not send ticket! ${ticketResponse.statusText}`);
      }

      const ticketResponseBody = await ticketResponse.json();

      return ticketResponseBody.ticketCreated;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      setTicketIsSending(false);
    }
  }

  /***** Private functions *****/
  function _getFormattedHomeyApps() {
    const homeyApps = dataManager.getValueByKey(dataKeys.homeyApps);

    if (homeyApps) {
      return homeyApps.map(app => {
        return `${app.id} (v${app.version})`;
      }).join("\n");
    }
  }

  function _getTicketData() {
    return {
      // General data
      "subject": dataManager.getValueByKey(dataKeys.subject).text,
      "message": dataManager.getValueByKey(dataKeys.message).text,
      "name": `${user.firstname} ${user.lastname}`,
      "email": user.email,
      "tags": dataManager.getFlatArrayOfUniqueValuesByKey(dataKeys.tags),
      // Homey data
      "diagnosticReport": dataManager.getValueByKey(dataKeys.diagnosticReport),
      "homeyApps": _getFormattedHomeyApps(),
      "homeyAppsCount": dataManager.getValueByKey(dataKeys.homeyApps).length,
      "homeyBridges": dataManager.getValueByKey(dataKeys.homeyBridges),
      "homeyId": dataManager.getValueByKey(dataKeys.homeyId),
      "homeyLanguage": dataManager.getValueByKey(dataKeys.homeyLanguage),
      "homeyLicenses": dataManager.getValueByKey(dataKeys.homeyLicenses),
      "homeyModel": dataManager.getValueByKey(dataKeys.homeyModel),
      "homeyModelName": dataManager.getValueByKey(dataKeys.homeyModelName),
      "homeyPlatform": dataManager.getValueByKey(dataKeys.homeyPlatform),
      "homeyRegion": dataManager.getValueByKey(dataKeys.homeyRegion),
      "homeySoftwareVersion": dataManager.getValueByKey(dataKeys.homeySoftwareVersion),
      "homeyUsers": dataManager.getValueByKey(dataKeys.homeyUsers),
      "underVoltagePercentage": dataManager.getValueByKey(dataKeys.underVoltagePercentage),
      // App data
      "appId": dataManager.getValueByKey(dataKeys.appId),
      // Order data
      "orderId": dataManager.getValueByKey(dataKeys.orderId),
      // Subscription data
      "subscriptionId": dataManager.getValueByKey(dataKeys.subscriptionId),
      "subscriptions": Object.keys(user.subscriptions).join(", "),
      // AI generated subject
      "supportFormPath": dataManager.getFlatArrayOfUniqueValuesByKey(dataKeys.path).join(" \u2192 "),
    }
  }

  function _getAttachments() {
    return dataManager.getValueByKey(dataKeys.files);
  }

  return [{ sendTicket }, ticketIsSending, ticketError, setTicketError]
}
