export const dataKeys = {
  // General
  articles: "articles",
  tags: "tags",
  subject: "subject",
  message: "message",
  files: "files",
  showValidation: "showValidation",
  // Homey
  diagnosticReport: "diagnosticReport",
  homeyApps: "homeyApps",
  homeyBridges: "homeyBridges",
  homeyId: "homeyId",
  homeyLanguage: "homeyLanguage",
  homeyLicenses: "homeyLicenses",
  homeyModel: "homeyModel",
  homeyModelName: "homeyModelName",
  homeyPlatform: "homeyPlatform",
  homeyRegion: "homeyRegion",
  homeySoftwareVersion: "homeySoftwareVersion",
  homeyUsers: "homeyUsers",
  underVoltagePercentage: "underVoltagePercentage",
  // App
  appId: "appId",
  appAuthor: "appAuthor",
  appBuild: "appBuild",
  supportType: "supportType",
  // Orders
  orderId: "orderId",
  // Subscriptions
  subscriptionId: "subscriptionId",
  // AI generated subject
  path: "path",
}
